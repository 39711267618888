import React, { useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { Row, Col, Form, Button } from "react-bootstrap";

import api from "../../services/api";
import { login, isAuthenticated } from "../../services/auth";

function Login(props) {
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    useEffect(() => {
        if (isAuthenticated()) {
            props.history.push("/app");
        }
    });

    async function submitLogin(e) {
        e.preventDefault();

        const responseLoginRequest = await api.post("/api/login", {
            email: emailRef.current.value,
            password: passwordRef.current.value,
        });

        console.log(responseLoginRequest);

        if (!responseLoginRequest.data.error) {
            login(responseLoginRequest.data.token);
            props.history.push("/app");
        } else {
            alert("Dados de login incorretos.");
        }
    }

    return (
        <Row>
            <Col
                md={{
                    offset: 5,
                    span: 2,
                }}
            >
                <Form
                    style={{
                        marginTop: 100,
                    }}
                    onSubmit={submitLogin}
                >
                    <h1>Gerador</h1>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="E-mail"
                            ref={emailRef}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Senha"
                            ref={passwordRef}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Fazer login
                    </Button>
                </Form>
            </Col>
        </Row>
    );
}

export default withRouter(Login);
