import React from "react";
import { withRouter } from "react-router-dom";

import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import { logout } from "../../services/auth";

function NavbarCustom(props) {
    return (
        <Navbar bg="dark" variant="dark">
            <Navbar.Brand href="">Gerador</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="">Home</Nav.Link>
                </Nav>
                <Nav>
                    <NavDropdown title="Minha conta" id="basic-nav-dropdown">
                        <NavDropdown.Item
                            href=""
                            onClick={(e) => {
                                e.preventDefault();

                                logout();
                                props.history.push("/");
                            }}
                        >
                            Sair
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default withRouter(NavbarCustom);
