import React, { useState, useEffect } from "react";
import axios from "axios";

import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";

import Select from "react-select";

import LoadingOverlay from "react-loading-overlay";

import NavbarCustom from "../../components/NavbarCustom";

import api from "../../services/api";

function Dashboard() {
    const [loader, setLoader] = useState(true);
    // const [city, setCity] = useState("Maringá");
    // const [seller1Name, setSeller1Name] = useState("Leonardo Mello");
    // const [seller1CPF, setSeller1CPF] = useState("10226733947");
    // const [seller2Name, setSeller2Name] = useState("Leonardo Mello");
    // const [seller2CPF, setSeller2CPF] = useState("10226733947");
    // const [seller3Name, setSeller3Name] = useState("Leonardo Mello");
    // const [seller3CPF, setSeller3CPF] = useState("10226733947");
    // const [seller4Name, setSeller4Name] = useState("Leonardo Mello");
    // const [seller4CPF, setSeller4CPF] = useState("10226733947");
    // const [seller5Name, setSeller5Name] = useState("Leonardo Mello");
    // const [seller5CPF, setSeller5CPF] = useState("10226733947");
    // const [seller6Name, setSeller6Name] = useState("Leonardo Mello");
    // const [seller6CPF, setSeller6CPF] = useState("10226733947");
    // const [seller7Name, setSeller7Name] = useState("Leonardo Mello");
    // const [seller7CPF, setSeller7CPF] = useState("10226733947");
    // const [seller8Name, setSeller8Name] = useState("Leonardo Mello");
    // const [seller8CPF, setSeller8CPF] = useState("10226733947");
    // const [seller9Name, setSeller9Name] = useState("Leonardo Mello");
    // const [seller9CPF, setSeller9CPF] = useState("10226733947");

    const [city, setCity] = useState("");
    const [seller1Name, setSeller1Name] = useState("");
    const [seller1CPF, setSeller1CPF] = useState("");
    const [seller2Name, setSeller2Name] = useState("");
    const [seller2CPF, setSeller2CPF] = useState("");
    const [seller3Name, setSeller3Name] = useState("");
    const [seller3CPF, setSeller3CPF] = useState("");
    const [seller4Name, setSeller4Name] = useState("");
    const [seller4CPF, setSeller4CPF] = useState("");
    const [seller5Name, setSeller5Name] = useState("");
    const [seller5CPF, setSeller5CPF] = useState("");
    const [seller6Name, setSeller6Name] = useState("");
    const [seller6CPF, setSeller6CPF] = useState("");
    const [seller7Name, setSeller7Name] = useState("");
    const [seller7CPF, setSeller7CPF] = useState("");
    const [seller8Name, setSeller8Name] = useState("");
    const [seller8CPF, setSeller8CPF] = useState("");
    const [seller9Name, setSeller9Name] = useState("");
    const [seller9CPF, setSeller9CPF] = useState("");

    const [imageGenerated, setImageGenerated] = useState("");
    const [cities, setCities] = useState([]);

    useEffect(() => {
        async function loadCities() {
            const cities = await axios.get(
                "https://servicodados.ibge.gov.br/api/v1/localidades/municipios"
            );

            const citiesBeautiful = await Promise.all(
                cities.data.map((city, index) => ({
                    value: city.nome,
                    label: city.nome,
                }))
            );

            setLoader(false);
            setCities(citiesBeautiful);
        }

        loadCities();
    }, []);

    async function onSumitForm(e) {
        e.preventDefault();

        setLoader(true);

        const responseGenerator = await api.post("/api/gerador-poster-seller", {
            city,
            seller1_name: seller1Name,
            seller1_cpf: seller1CPF,
            seller2_name: seller2Name,
            seller2_cpf: seller2CPF,
            seller3_name: seller3Name,
            seller3_cpf: seller3CPF,
            seller4_name: seller4Name,
            seller4_cpf: seller4CPF,
            seller5_name: seller5Name,
            seller5_cpf: seller5CPF,
            seller6_name: seller6Name,
            seller6_cpf: seller6CPF,
            seller7_name: seller7Name,
            seller7_cpf: seller7CPF,
            seller8_name: seller8Name,
            seller8_cpf: seller8CPF,
            seller9_name: seller9Name,
            seller9_cpf: seller9CPF,
        });

        if (responseGenerator.data.error) {
            alert(
                "Problemas ao gerar o cartaz. Verifique os CPFS! Se o erro persistir, contate o suporte!"
            );
            setLoader(false);
        } else {
            setLoader(false);
            setImageGenerated(responseGenerator.data.image);
        }
    }

    return (
        <>
            <NavbarCustom />
            <LoadingOverlay active={loader} spinner text="Gerando...">
                <Container>
                    <Row>
                        <Col>
                            <h1>Olá, bem-vindo ao gerador.</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Form onSubmit={onSumitForm}>
                                <label>Cidade</label>
                                <Select
                                    options={cities}
                                    placeholder="Escolha uma cidade"
                                    onChange={(e) => setCity(e.value)}
                                />
                                {/* <Form.Group>
                                    <Form.Label>Cidade</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={city}
                                        onChange={(e) =>
                                            setCity(e.target.value)
                                        }
                                    >
                                        <option>Escolha uma cidade</option>
                                        {cities.map((city, index) => (
                                            <option
                                                key={index}
                                                value={city.nome}
                                            >
                                                {city.nome}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group> */}
                                <br />
                                <Form.Group>
                                    <Form.Label>Nome Vendedor 1</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: Antonio Casagrande"
                                        value={seller1Name}
                                        onChange={(e) =>
                                            setSeller1Name(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>CPF Vendedor 1</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: 45345665456"
                                        value={seller1CPF}
                                        onChange={(e) =>
                                            setSeller1CPF(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Nome Vendedor 2</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: Antonio Casagrande"
                                        value={seller2Name}
                                        onChange={(e) =>
                                            setSeller2Name(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>CPF Vendedor 2</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: 45345665456"
                                        value={seller2CPF}
                                        onChange={(e) =>
                                            setSeller2CPF(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Nome Vendedor 3</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: Antonio Casagrande"
                                        value={seller3Name}
                                        onChange={(e) =>
                                            setSeller3Name(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>CPF Vendedor 3</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: 45345665456"
                                        value={seller3CPF}
                                        onChange={(e) =>
                                            setSeller3CPF(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Nome Vendedor 4</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: Antonio Casagrande"
                                        value={seller4Name}
                                        onChange={(e) =>
                                            setSeller4Name(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>CPF Vendedor 4</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: 45345665456"
                                        value={seller4CPF}
                                        onChange={(e) =>
                                            setSeller4CPF(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Nome Vendedor 5</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: Antonio Casagrande"
                                        value={seller5Name}
                                        onChange={(e) =>
                                            setSeller5Name(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>CPF Vendedor 5</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: 45345665456"
                                        value={seller5CPF}
                                        onChange={(e) =>
                                            setSeller5CPF(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Nome Vendedor 6</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: Antonio Casagrande"
                                        value={seller6Name}
                                        onChange={(e) =>
                                            setSeller6Name(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>CPF Vendedor 6</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: 45345665456"
                                        value={seller6CPF}
                                        onChange={(e) =>
                                            setSeller6CPF(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Nome Vendedor 7</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: Antonio Casagrande"
                                        value={seller7Name}
                                        onChange={(e) =>
                                            setSeller7Name(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>CPF Vendedor 7</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: 45345665456"
                                        value={seller7CPF}
                                        onChange={(e) =>
                                            setSeller7CPF(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Nome Vendedor 8</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: Antonio Casagrande"
                                        value={seller8Name}
                                        onChange={(e) =>
                                            setSeller8Name(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>CPF Vendedor 8</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: 45345665456"
                                        value={seller8CPF}
                                        onChange={(e) =>
                                            setSeller8CPF(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Nome Vendedor 9</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: Antonio Casagrande"
                                        value={seller9Name}
                                        onChange={(e) =>
                                            setSeller9Name(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>CPF Vendedor 9</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: 45345665456"
                                        value={seller9CPF}
                                        onChange={(e) =>
                                            setSeller9CPF(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Gerar!
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                    {imageGenerated !== "" ? (
                        <Row
                            style={{
                                marginTop: 40,
                            }}
                        >
                            <Col>
                                <Image
                                    src={`data:image/png;base64,${imageGenerated}`}
                                    rounded
                                    fluid
                                />
                            </Col>
                        </Row>
                    ) : null}
                </Container>
            </LoadingOverlay>
        </>
    );
}

export default Dashboard;
